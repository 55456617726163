import React from 'react';
import ReactDOM from 'react-dom';
import { PSI } from './components/psi';
import 'regenerator-runtime/runtime.js';
import { Route, BrowserRouter } from 'react-router-dom';

const rootElement = document.getElementById('root');
ReactDOM.render(
  <BrowserRouter>
    <Route path="/" component={PSI} />
  </BrowserRouter>,
  rootElement,
);
