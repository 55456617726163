import React from 'react';
import { useStyles } from './styles';

const BottomText = () => {
  const classes = useStyles();

  return (
    <div id={'lh-bottom-text'} className={classes.bottomContainer}>
      <h3>
        <span className={classes.title}>Predicting AMP page performance when viewed from the search results</span>
      </h3>
      <p>
        <span>
          AMP derives its high performance from factors that are &lsquo;activated&rsquo; only when the AMP page is
          served from the Google search results, but not when it is loaded directly. Available speed test tools load the
          AMP page directly as if it was plain HTML, which significantly undervalues AMP performance scores compared to
          how Google will actually rank the AMP page.
        </span>
      </p>
      <p>
        <span>
          The AMP Predicted Performance Tool uses the &lsquo;direct loading&rsquo; score (i.e. the score the AMP page
          receives when it is loaded directly from our servers) as a base to predict the score the AMP page will have
          when it is served and measured by Google in the search results (expand the metrics section above for details
          about the predictions).
        </span>
      </p>
      <p>
        <span>
          Important note: the base score upon which the tool predicts is the score achieved by the direct loading of the
          AMP version of the page, not the source HTML version.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          The following accelerating factors are not taken into account when an AMP page is measured directly:
        </span>
      </p>
      <ol>
        <li aria-level="1">
          <p>
            <span>Page Pre-loading</span>
          </p>
          <ul>
            <li aria-level="2">
              <p>
                <span>How it improves performance</span>
                <span>
                  : AMP pages are pre-loaded to the visitors devices before being opened. When the click to open the
                  page takes place - the already loaded page appears in an instant. Plus, The heaviest components of an
                  AMP page are shared across all AMP pages. Most visitors already have them in their device from
                  previous visits to other AMP pages and they don&rsquo;t require download.
                </span>
              </p>
            </li>
            <li aria-level="2">
              <p>
                <span>What happens in traditional Speed Test</span>
                <span>
                  : In web speed tests, all the components are fully downloaded + no preloading takes place. These
                  factors reduce the LCP (Largest Contentful Paint), the FCP (First Contentful Paint), and affect the
                  other Core-Web Vitals signals.
                </span>
              </p>
            </li>
          </ul>
        </li>
        <li aria-level="1">
          <p>
            <span>AMP Cache</span>
          </p>
          <ul>
            <li aria-level="2">
              <p>
                <span>How it improves performance</span>
                <span>
                  : AMP pages are served by Google after being cached on Google&apos;s servers, optimized (images
                  resize, prefetch hints, minify html and CSS, etc.), and distributed to Google&rsquo;s
                  Content-Delivery-Network (CDN). These measures significantly improve performance when the AMP page is
                  viewed from the search results compared to direct measurement. .
                </span>
              </p>
            </li>
            <li aria-level="2">
              <p>
                <span>What happens in the Speed Test</span>
                <span>
                  : The AMP pages load directly from Ampify&apos;s servers (even if the server is on the other side of
                  the world) without usage of the CDN to load the page from a nearby server. The loaded pages also lack
                  further optimizations that the AMP cache applies.
                </span>
              </p>
            </li>
          </ul>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          The result is that a page speed score of 67 can be as high as 92, when viewed in the Google Search results.
        </span>
      </p>
      <p>
        <span>The AMP Prediction Tool provides a way to know what to expect of the AMP page when it will go live.</span>
      </p>
      <p>
        <span>
          <span>
            <img
              src="https://lh6.googleusercontent.com/fLTuiVJn8srJt1jZcYtEV_t2Xb0PdDdJEBF_wqpmhGbqIuRmPEMAWAUUY4e_vrA8kKrZvRpxpMF8RWvArMwaHK8AmRQZqnQW0DmwSTLk08xKKZAp1-RTiN6MBU3FmhsLlYR7hln5"
              width="624"
              height="235"
            />
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li aria-level="1">
          <p>
            <span>More about the data Google displays in the Speed Test:&nbsp;</span>
            <a href="https://developers.google.com/speed/docs/insights/v5/about#faq">
              <span>developers.google.com/speed/docs/insights/v5/about#faq</span>
            </a>
          </p>
        </li>
        <li aria-level="1">
          <p>
            <span>More about direct measurement of AMP page performance:&nbsp;</span>
            <a href="https://blog.amp.dev/2018/01/17/measuring-amp-performance/">
              <span>blog.amp.dev/2018/01/17/measuring-amp-performance/</span>
            </a>
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <span>
          *The AMP Prediction Tool uses statistics about the behavior of AMP pages to predict the performance of
          individual pages. It should be known that actual results are subjected to multiple ranking factors, many of
          them are unknown, and may be measured differently by Google.
        </span>
      </p>
    </div>
  );
};

export default BottomText;
