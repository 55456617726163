import React, { useEffect } from 'react';
import axios from 'axios';
// import React2LighthouseViewer from '@ampify/lighthouse-viewer';
import { useLocation, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Loader from '../../assets/load.svg';
import { useStyles } from './styles';
import { BottomText } from '../Bottom';
import { Tooltip } from '@material-ui/core';
import React2LighthouseViewer from "react2-lighthouse-viewer";

const PSI = () => {
  const classes = useStyles();

  const { amp_url, canonical_url } = queryString.parse(useLocation().search);
  const [result, setResult] = React.useState(undefined);
  useEffect(() => {
    const options = {
      method: 'GET',
      params: { url: amp_url, key: 'AIzaSyAnXvYIDuB4Wfg08hK8zCHkpzAEE52Ptiw', strategy: 'MOBILE' },
      url: `https://www.googleapis.com/pagespeedonline/v5/runPagespeed`,
    };
    axios(options)
      .then(data => {
        const final = manipulate(data.data.lighthouseResult);
        setResult(final);
      })
      .catch(e => {
        console.error(e);
      });
  }, [amp_url]);
  return (
    <div id="app" className={classes.root}>
      <span id={'lh-title'} className={classes.title}>
        AMP Page Speed Insights
      </span>
      <a id={'lh-subtitle'} className={classes.subtitle} target="_blank" rel="noopener" href={canonical_url}>
        {canonical_url}
      </a>
      {result ? <React2LighthouseViewer json={result} /> : <Loader className={classes.circular} />}
      <div className={classes.speedPreviewContainer}>
        <Tooltip
          classes={{ tooltip: classes.tooltipText }}
          interactive
          placement={'top'}
          title={
            <span>
              <span>In the new tab, click “PREVIEW RESULTS”</span>
            </span>
          }
        >
          <a
            id={'g-speed-link'}
            target="_blank"
            rel="noopener"
            href={`https://search.google.com/test/amp?url=${amp_url}`}
            className={classes.speedPreview}
          >
            Open Speed Preview
          </a>
        </Tooltip>
      </div>
      <br />
      <BottomText />
    </div>
  );
};

const manipulate = (lighthouseResult, ampUrl) => {
  lighthouseResult = manipulateScores(lighthouseResult);
  lighthouseResult = manipulateTexts(lighthouseResult, ampUrl);
  return lighthouseResult;
};

const manipulateTexts = lighthouseResult => {
  lighthouseResult['categories']['performance'][
    'description'
  ] = `This is a prediction for the AMP page performance score when it is served from the Google search results. [Learn more](${window
    .location.href + '#lh-bottom-text'})`;

  lighthouseResult['audits']['first-contentful-paint']['description'] =
    'When viewed from the Google search results, the top elements of the page are pre-rendered, making FCP time 50% shorter.';
  lighthouseResult['audits']['speed-index']['description'] =
    'Speed Index is 70% higher in AMP pages thanks to shorter loading times.';
  lighthouseResult['audits']['largest-contentful-paint']['description'] =
    'AMP pages which are pre-loaded by Google in the search results have LCP which improves by over 60%.';
  lighthouseResult['audits']['total-blocking-time']['description'] =
    'When viewed from the Google search results, blocking time nearly doesn’t exist since the page is mostly ready in the background.';
  lighthouseResult['audits']['interactive']['description'] =
    'Above-the-fold content is rendered before loading the page, making the time to interaction is at least 10% shorter.';
  lighthouseResult['audits']['cumulative-layout-shift']['description'] =
    'CLS is the only factor which is the same when an AMP page is loaded directly vs. viewed from the search results. Usually it’s close to 0.';
  return lighthouseResult;
};

const manipulateScores = lighthouseResult => {
  const fcp = {
    p10: 1800,
    median: 3000,
  };
  const speed = {
    p10: 3387,
    median: 5800,
  };
  const lcp = {
    p10: 2500,
    median: 4000,
  };
  const tti = {
    p10: 3785,
    median: 7300,
  };
  const tbtScoring = {
    p10: 200,
    median: 600,
  };

  const updatedFCP = lighthouseResult['audits']['first-contentful-paint']['numericValue'] / 2;
  lighthouseResult['audits']['first-contentful-paint']['numericValue'] = updatedFCP;
  lighthouseResult['audits']['first-contentful-paint']['displayValue'] = `${(updatedFCP / 1000).toFixed(1)} s`;
  lighthouseResult['audits']['first-contentful-paint']['score'] = computeScore(fcp, updatedFCP);

  const updatedSI = lighthouseResult['audits']['speed-index']['numericValue'] / 3;
  lighthouseResult['audits']['speed-index']['numericValue'] = updatedSI;
  lighthouseResult['audits']['speed-index']['displayValue'] = `${(updatedSI / 1000).toFixed(1)} s`;
  lighthouseResult['audits']['speed-index']['score'] = computeScore(speed, updatedSI);

  const updatedLCP = lighthouseResult['audits']['largest-contentful-paint']['numericValue'] * 0.4;
  lighthouseResult['audits']['largest-contentful-paint']['numericValue'] = updatedLCP;
  lighthouseResult['audits']['largest-contentful-paint']['displayValue'] = `${(updatedLCP / 1000).toFixed(1)} s`;
  lighthouseResult['audits']['largest-contentful-paint']['score'] = computeScore(lcp, updatedLCP);

  const tbt = lighthouseResult['audits']['total-blocking-time']['numericValue'];
  if (tbt > 200) {
    lighthouseResult['audits']['total-blocking-time']['numericValue'] = 200;
    lighthouseResult['audits']['total-blocking-time']['displayValue'] = `200 ms`;
    lighthouseResult['audits']['total-blocking-time']['score'] = 0.9;
  }
  const updatedInteractive = lighthouseResult['audits']['interactive']['numericValue'] * 0.9;
  lighthouseResult['audits']['interactive']['numericValue'] = updatedInteractive;
  lighthouseResult['audits']['interactive']['displayValue'] = `${(updatedInteractive / 1000).toFixed(1)} s`;
  lighthouseResult['audits']['interactive']['score'] = computeScore(tti, updatedInteractive);
  lighthouseResult['categories']['performance']['score'] = arithmeticMean(
    lighthouseResult['categories']['performance']['auditRefs'].map(auditRef => ({
      score: lighthouseResult['audits'][auditRef.id].score,
      weight: auditRef.weight,
    })),
  );
  return lighthouseResult;
};

const computeScore = (controlPoints, value) => {
  const percentile = getLogNormalScore(controlPoints, value);
  return clampTo2Decimals(percentile);
};

const clampTo2Decimals = val => Math.round(val * 100) / 100;

/**
 * Returns the score (1 - percentile) of `value` in a log-normal distribution
 * specified by the `median` value, at which the score will be 0.5, and a 10th
 * percentile value, at which the score will be 0.9. The score represents the
 * amount of the distribution greater than `value`. All values should be in the
 * same units (e.g. milliseconds). See
 *   https://www.desmos.com/calculator/o98tbeyt1t
 * for an interactive view of the relationship between these parameters and the
 * typical parameterization (location and shape) of the log-normal distribution.
 * @param {{median: number, p10: number}} parameters
 * @param {number} value
 * @return {number}
 */
function getLogNormalScore({ median, p10 }, value) {
  // Required for the log-normal distribution.
  if (median <= 0) throw new Error('median must be greater than zero');
  if (p10 <= 0) throw new Error('p10 must be greater than zero');
  // Not required, but if p10 > median, it flips around and becomes the p90 point.
  if (p10 >= median) throw new Error('p10 must be less than the median');

  // Non-positive values aren't in the distribution, so always 1.
  if (value <= 0) return 1;

  // Closest double to `erfc-1(2 * 1/10)`.
  const INVERSE_ERFC_ONE_FIFTH = 0.9061938024368232;

  // Shape (σ) is `log(p10/median) / (sqrt(2)*erfc^-1(2 * 1/10))` and
  // standardizedX is `1/2 erfc(log(value/median) / (sqrt(2)*σ))`, so simplify a bit.
  const xLogRatio = Math.log(value / median);
  const p10LogRatio = -Math.log(p10 / median); // negate to keep σ positive.
  const standardizedX = (xLogRatio * INVERSE_ERFC_ONE_FIFTH) / p10LogRatio;
  const complementaryPercentile = (1 - erf(standardizedX)) / 2;

  // Clamp to [0, 1] to avoid any floating-point out-of-bounds issues.
  return Math.min(1, Math.max(0, complementaryPercentile));
}

/**
 * Approximates the Gauss error function, the probability that a random variable
 * from the standard normal distribution lies within [-x, x]. Moved from
 * traceviewer.b.math.erf, based on Abramowitz and Stegun, formula 7.1.26.
 * @param {number} x
 * @return {number}
 */
function erf(x) {
  // erf(-x) = -erf(x);
  const sign = Math.sign(x);
  x = Math.abs(x);

  const a1 = 0.254829592;
  const a2 = -0.284496736;
  const a3 = 1.421413741;
  const a4 = -1.453152027;
  const a5 = 1.061405429;
  const p = 0.3275911;
  const t = 1 / (1 + p * x);
  const y = t * (a1 + t * (a2 + t * (a3 + t * (a4 + t * a5))));
  return sign * (1 - y * Math.exp(-x * x));
}

const arithmeticMean = items => {
  // Filter down to just the items with a weight as they have no effect on score
  items = items.filter(item => item.weight > 0);
  // If there is 1 null score, return a null average
  if (items.some(item => item.score === null)) return null;

  const results = items.reduce(
    (result, item) => {
      const score = item.score;
      const weight = item.weight;

      return {
        weight: result.weight + weight,
        sum: result.sum + /** @type {number} */ (score) * weight,
      };
    },
    { weight: 0, sum: 0 },
  );

  return clampTo2Decimals(results.sum / results.weight || 0);
};
export default withRouter(PSI);
