import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  bottomContainer: {
    'max-width': '880px',
  },
  title: {
    color: '#212121',
  },
}));

export { useStyles };
