import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100vw',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'flex-direction': 'column',
    'margin-top': '66px',
    'font-family': 'Roboto, Helvetica, Arial, sans-serif',
    color: '#424242',
  },
  title: {
    'font-size': '28px',
    'margin-bottom': '10px',
    color: '#212121',
  },
  subtitle: {
    'font-size': '16px',
    'text-decoration': 'none',
    color: '#424242',
  },
  circular: {
    'min-height': '682px',
  },
  speedPreviewContainer: {
    'max-width': '880px',
    width: '100vw',
  },
  speedPreview: {
    padding: '15px',
    'background-color': '#2962ff',
    color: 'white',
    'border-radius': '100px',
    'border-width': '2px',
    'border-style': 'solid',
    '&:hover': {
      'border-color': '#000000',
    },
    'text-decoration': 'none',
  },
  tooltipText: {
    fontSize: '12px',
  },
}));
export { useStyles };
